
  
  export default function TopHead({ setIsInfoModalOpen , headerTitle }) {
    const updateModalState = () => {
      setIsInfoModalOpen(true); 
    };
  
    return (
      <div className="top-head-outline">
        <div className="top-head">
          <div className="top-brand-name">
            <b>News Word</b> {headerTitle}
          </div>
          <div>
            <img
              onClick={updateModalState}
              className="how-to-play-icon"
              src="/Vector.png"
              alt="How to play"
            />
          </div>
        </div>
      </div>
    );
  }
  