import React, { useState, useEffect } from "react";


export default function TriesCount({
  answer,
  winpercentage,
  isGameLost,
  isGameWon,
  windowHeight,
}) {
    const value = localStorage.getItem("gameState");
    const parsedValue = value ? JSON.parse(value) : null; 
    const triesCount = parsedValue?.guesses?.length || 0; 
    const tryCount = Math.min(triesCount + 1, 6);
    const attemptCount = triesCount;
    const [delayTime, setDelayTime] = useState(false);
    const IsImageShow =  localStorage.getItem('resultImageShow') ;
    const RESULT_IMAGE_TIME =  0;

    

  let image = "";
  let text = "";
  if (isGameWon) {
    image = "/welldone.png";
    text = "Well done!";
  }
  if (isGameLost) {
    image = "/shitt.png";
    text = "Next Time!";
    
  }
  useEffect(() => {
    if(isGameWon || isGameLost) {
      setDelayTime(true);
    }  
    
  }, [ isGameWon , isGameLost ]);
 
  return (
    <div className="tries-count-body">
      <div className="tries-count"  style={{ height: `${windowHeight - 408}px` }}>
        {(isGameWon || isGameLost) && delayTime == true ? (
          <>
            <div style={{ textAlign: "center" }}>
            <div className="flex justify-center" >
              <img style={{ height: "80px" }} src={image} />
              </div>
              <h1 className="padding-on-fit-screen" style={{  fontSize:'40px', fontWeight: "bold", }}>{text}</h1>
            </div>

            <div>
              {isGameWon ? (
                <p
                  style={{
                    padding: "0 15px",
                    fontSize: "17px",
                    textAlign: "center",
                    lineHeight: "1",
                  }}
                >
                  You are among the {winpercentage}% of <br /> people who solved
                  today’s word <br /> in {attemptCount} { attemptCount > 1 ? ' tries' : ' try' }.
                </p>
              ) : (
                <div style={{textAlign:'center'}}>
                  <h6 style={{fontSize:'16px', fontWeight:'700', margin:'0px'}} >ANSWER</h6>
                  <h4 style={{fontSize:'32px', fontWeight:'700', textTransform: 'uppercase' }} >{answer}</h4>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{ fontWeight: "bold", margin: "0", lineHeight: "0.75" }}
              >
                TRIES
              </h5>
              <h1 style={{ fontWeight: "bold", fontSize:'45px', marginTop:'5px'  }}>{triesCount}/6</h1>
            </div>

            <div>
              <p
                style={{
                  padding: "12px 15px",
                  fontSize: "17px",
                  textAlign: "center",
                  lineHeight: "1",
                  margin: "0",
                }}
              >
                {winpercentage}% of people solved today’s <br /> word in {' '}
                {tryCount}{ attemptCount > 0 ? ' tries' : ' try' }.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
