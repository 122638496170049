import { useLocation, useParams } from 'react-router-dom';
import Result from './Result';
import { GameStats } from '../../lib/localStorage';
import TopHead from '../header/TopHead';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { Oval } from 'react-loader-spinner';
import { addStatsForCompletedGame, loadStats } from '../../lib/stats';
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from '../../lib/localStorage'
import {
  isWordInWordList,
  isWinningWord,
  getWordOfDay,
  solution as sl,
  findFirstUnusedReveal,
} from '../../lib/words'
import { InfoModal } from '../modals/InfoModal';
import { GAME_COPIED_MESSAGE } from '../../constants/strings';
import { ALERT_TIME_MS } from '../../constants/settings';
import { Alert } from '../alerts/Alert';
// import {
//   STATISTICS_TITLE,
//   RELATED_INFO,
//   GUESS_DISTRIBUTION_TEXT,
//   NEW_WORD_TEXT,
//   SHARE_TEXT,
// } from '../../constants/strings'



const ResultPage = () => {

  const params = useParams()

  // const API_URL = process.env.REACT_APP_API_URL
  const BASE_URL = process.env.REACT_APP_BASE_URL
  let API_URL = 'https://staging.newsword.org/member-area/api/';

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isShowLoader, setShowLoader] = useState(false)
  const [headerTitle, setHeaderTitle] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [articleUrl, setArticleUrl] = useState(null);
  const [puzzleHeadline, setPuzzleHeadline] = useState('');
  const [wordData, setWordData] = useState({
    id: '',
    word: '',
    run_date: '',
    answer_summery: '',
    answer_url: '',
    logo: '',
  })
  const [currentDate, setCurrentDate] = useState('')
  const [successAlert, setSuccessAlert] = useState('')
  const isGameLost = !!localStorage.getItem('isGameLost');
  const isGameWon = !!localStorage.getItem('isGameWon');
  
  
  // const [solution, setSolution] = useState(sl)
  // const [guesses, setGuesses] = useState<string[]>(() => {
  //   const loaded = loadGameStateFromLocalStorage()
  //   if (loaded?.solution !== solution) {
  //     return []
  //   }
  //   // console.log('solutions', wordData)
  //   // if (loaded.guesses.length) {
  //   //   evaluate(solution, loaded)
  //   // }

  //   return loaded.guesses
  // })
  useEffect(() => {

    if (isGameWon == false && isGameLost == false) {
      window.location.href = `/${params.company}`
    }
    



    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    var todayDate = yyyy + '-' + mm + '-' + dd
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today)
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(today)
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today)

    var currentMonthAndDate = mo + ' ' + da
    setCurrentDate(currentMonthAndDate)

    if (params.company) {
      setShowLoader(true)
      axios
        .get(API_URL + `get-company-url?url=${params.company}`)
        .then((response) => {
          // console.log(response);
          if (response.data.success === true) {
            setPuzzleHeadline(response.data.data.headline)
            setClientName(response.data.data.name.toUpperCase())
            setArticleUrl(response.data.data.article_url)
            setHeaderTitle(response.data?.data?.puzzle_header_title)
           
          }
          if (response.data.puzzle === true) {
            // console.log(response.data.data);
            setWordData(response.data.data)

          } else {
            if (typeof response.data.data !== 'undefined') {
              setWordData(response.data.data)
            }
          }

          if (typeof response.data.data != 'undefined') {
            if (response.data.data.word) {
              getWordOfDay(response.data.data.word)

              // localStorage.setItem('word', wordData.word)
            }
            // if (response.data.data.run_date) {
            //   localStorage.setItem('run_date', wordData.run_date)
            // }
            // if (ClearStorage) { //this comment by wahab on 07/09/2023
            //   if (wordData.id) {
            //     localStorage.setItem('puzzle_id', btoa(wordData.id))
            //   }
            // }
          }
          let puzzle_id: string =
                    localStorage.getItem('puzzle_id') || ''
                  puzzle_id = atob(puzzle_id)
          if (
            localStorage.getItem('run_date') !=
            response.data.data.run_date ||
            puzzle_id != response.data.data.id

          ) {
            // alert(puzzle_id);
            saveGameStateToLocalStorage({ guesses: [], solution: '' })
            // console.log('one');
            
            localStorage.removeItem('welcomePage')
            localStorage.removeItem('resultImageShow')
            localStorage.removeItem('isGameWon');
            localStorage.removeItem('isGameLost');
            window.location.href = `/${params.company}`

          }

          setShowLoader(false)
        })
    }
   
  }, [])


  const gameState = localStorage.getItem('gameState');
  let guesses: string[] = [];
  if (gameState) {
    try {
      const parsedGameState = JSON.parse(gameState);
      if (Array.isArray(parsedGameState.guesses)) {
        guesses = parsedGameState.guesses;
      }
    } catch (error) {
      console.error('Error parsing gameState:', error);
    }
  }



  const [stats, setStats] = useState(() => loadStats())
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // const articleUrl = queryParams.get('articleUrl') || '';
  // const puzzleHeadline = queryParams.get('puzzleHeadline') || '';
  // const headerTitle = queryParams.get('headerTitle') || '';
  const isOpen = queryParams.get('isOpen') === 'true';
  // const guesses = queryParams.get('guesses')?.split(',') || []; 
  // const gameStats = JSON.parse(queryParams.get('gameStats') || '{}');
  // const isGameLost = queryParams.get('isGameLost') === 'true'; 
  // const isGameWon = queryParams.get('isGameWon') === 'true'; 
  // const date = new Date(queryParams.get('date') ||''); 
  const Statistics = queryParams.get('Statistics') === 'true';
  // const wordData = JSON.parse(queryParams.get('wordData') || '{}'); 
  const showTimer = queryParams.get('showTimer') === 'true';
  const isHardMode = queryParams.get('isHardMode') === 'true';

  // console.log('Query Parameters:', queryParams.toString());

  if (isGameWon == false && isGameLost == false) {
    return (<div></div>);
  }
  return (
    <div>
       {/* style={{minHeight:'100vh'}} */}
      {isShowLoader == true ? (
        <div className="loader-main" >
          <Oval height="50" width="50" color="grey" ariaLabel="loading" />
        </div>
      ) : (
        ''
      )}

      <div>
        <TopHead setIsInfoModalOpen={setIsInfoModalOpen} headerTitle={headerTitle} />
        <div className='result_page_main' >
          <Result
            articleUrl={articleUrl}
            puzzleHeadline={puzzleHeadline}
            isOpen={isOpen}
            handleClose={() => { }}
            guesses={Array.isArray(guesses) ? guesses : []}
            gameStats={stats as GameStats}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            date={currentDate}
            Statistics={Statistics}
            wordData={wordData}
            showTimer={showTimer}
            handleShare={() => {
              setSuccessAlert(GAME_COPIED_MESSAGE)
              return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
            }}
            isHardMode={isHardMode}
          />
        </div>
        <InfoModal
          clientName={clientName}
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <Alert
          message={successAlert}
          isOpen={successAlert !== ''}
          variant="share"
          topMost={true}
        />
      </div>
    </div>
  );
};

export default ResultPage;
